<template>
  <div
    :key="ticket.id"
    class="ticket-list-container__item"
    :class="{'active-ticket': currentTicket && currentTicket.id === ticket.id}"
    @click="$emit('onTicketClick', ticket)"
  >
    <div class="ticket-list-container__icon">
      <IconSupportTicketActive
        v-if="isActive"
        :width="32"
        :height="32"
      />
      <IconSupportTicketClose
        v-else
        :width="32"
        :height="32"
      />
    </div>
    <div style="flex: 1">
      <div
        class="ticket-list-container__description"
        v-html="ticket.topic.title"
      />
      <div
        v-if="currentTicket && currentTicket.id === ticket.id"
        class="ticket-list-container__text"
      >
        {{ ticket.description }}
      </div>
      <div class="ticket-list-container__date">
        <p class="ticket-list-container__date__inner">
          {{ `${ticket.number || ''}  ${ticket.createdDate}` }} <b>{{ ticket.createdTime }}</b>
        </p>
        <p style="display: inline-block;">
          {{ isActive ? `Запрос открыт` : 'Проблема решена' }}
        </p>
      </div>
    </div>
    <div
      class="ticket-list-container__icon"
      style="position: relative"
    >
      <IconSupportTicketMessage
        :width="24"
        :height="24"
        :active="ticket.unreadMessages > 0"
      />
      <span
        v-if="isActive && ticket.unreadMessages && parseInt(ticket.unreadMessages) > 0"
        class="dot"
      >{{ ticket.unreadMessages }}</span>
    </div>
  </div>
</template>
<script>
import IconSupportTicketActive from '@/components/common/icons/IconSupportTicketActive'
import IconSupportTicketClose from '@/components/common/icons/IconSupportTicketClose'
import IconSupportTicketMessage from '@/components/common/icons/IconSupportTicketMessage'

export default {
  name: 'SupportTicketItem',
  components: { IconSupportTicketActive, IconSupportTicketClose, IconSupportTicketMessage },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    currentTicket: {
      type: Object,
      required: false,
      default: () => null,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {}
  },
}
</script>


