<template>
  <div class="offer__filter">
    <div class="filter-top">
      <div class="filter-top__left-group">
        <div
          class="filter-top__element filter-button"
          @click="onAddButtonClick"
        >
          <IconPlus />
          <span
            v-if="!smart"
            class="filter-top__element__text office__text-create"
          >{{ $t('support.ticket.add') }}</span>
          <p
            v-else
            style="margin-left: 20px"
          >
            <span
              v-for="item in statusGroups"
              :key="item.value"
              style="font-size: 16px"
              class="filter-top__element__text"
              :class="{
                'filter-top__element__text_active': filters.statusGroup === item.value,
                'filter-top__element__margin_right': item.hasMargin
              }"
              @click="changeStatus(item.value)"
            >{{ item.label }}</span>
          </p>
        </div>
      </div>
      <div
        v-if="!smart"
        class="filter-top__right-group"
      >
        <span
          v-for="item in statusGroups"
          :key="item.value"
          style="font-size: 16px"
          class="filter-top__element__text"
          :class="{
            'filter-top__element__text_active': filters.statusGroup === item.value,
            'filter-top__element__margin_right': item.hasMargin
          }"
          @click="changeStatus(item.value)"
        >{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import IconPlus from '@/components/common/icons/IconPlus'
import { modalName } from '@/views/PageOfferList'

export default {
  name: 'SupportFilterBarCenter',
  components: { IconPlus },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    smart: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      modalName,
      statusGroups: [
        { label: this.$t('support.filters.all'), value: 'all', count: '0' },
        { label: this.$t('support.filters.active'), value: 'active', count: '0' },
        { label: this.$t('support.filters.done'), value: 'done', count: '0' },
      ]
    }
  },
  methods: {
    changeStatus (type) {
      this.$emit('changeStatus', type)
    },
    onAddButtonClick () {
      this.$emit('onAddButtonClick')
    },
  }
}
</script>

<style lang="sass" scoped>
  .office
    &__filter
      display: flex
      flex-direction: column

    &__icon__grid
      margin-left: 50px
      margin-right: 20px
      cursor: pointer
    &__icon__table
      cursor: pointer

    &__text-management
      @extend %button-text
      color: rgba(0, 0, 0, 0.61)
      margin-left: 8px

    .filter-bottom
      margin-top: 20px

</style>
