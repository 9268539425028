var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offer__filter"},[_c('div',{staticClass:"filter-top"},[_c('div',{staticClass:"filter-top__left-group"},[_c('div',{staticClass:"filter-top__element filter-button",on:{"click":_vm.onAddButtonClick}},[_c('IconPlus'),(!_vm.smart)?_c('span',{staticClass:"filter-top__element__text office__text-create"},[_vm._v(_vm._s(_vm.$t('support.ticket.add')))]):_c('p',{staticStyle:{"margin-left":"20px"}},_vm._l((_vm.statusGroups),function(item){return _c('span',{key:item.value,staticClass:"filter-top__element__text",class:{
              'filter-top__element__text_active': _vm.filters.statusGroup === item.value,
              'filter-top__element__margin_right': item.hasMargin
            },staticStyle:{"font-size":"16px"},on:{"click":function($event){return _vm.changeStatus(item.value)}}},[_vm._v(_vm._s(item.label))])}),0)],1)]),(!_vm.smart)?_c('div',{staticClass:"filter-top__right-group"},_vm._l((_vm.statusGroups),function(item){return _c('span',{key:item.value,staticClass:"filter-top__element__text",class:{
          'filter-top__element__text_active': _vm.filters.statusGroup === item.value,
          'filter-top__element__margin_right': item.hasMargin
        },staticStyle:{"font-size":"16px"},on:{"click":function($event){return _vm.changeStatus(item.value)}}},[_vm._v(_vm._s(item.label))])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }