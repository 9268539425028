<template>
  <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g :opacity="active || fill ?  1 :0.4">
      <path d="M11.1939 7.73838L2.83365 0H19.5541L11.1939 7.73838Z" :fill="active ? activeColor : fill || 'black'"/>
      <path d="M0 2.15385C0 1.57021 0.237116 1.04078 0.62218 0.652879L7.59441 7.10651L0.736442 13.4543C0.284555 13.0598 0 12.4855 0 11.8462V2.15385Z" :fill="active ? activeColor : fill || 'black'"/>
      <path d="M3.06365 14H19.2302L13.288 8.49974L11.1938 10.4381L9.05274 8.45638L3.06365 14Z" :fill="active ? activeColor : fill || 'black'"/>
      <path d="M14.7464 7.14982L21.4081 13.316C21.7753 12.9312 22 12.4143 22 11.8462V2.15385C22 1.66409 21.833 1.21252 21.5518 0.850703L14.7464 7.14982Z" :fill="active ? activeColor : fill || 'black'"/>
    </g>
  </svg>
</template>

<script>
  import icon_config from "@/mixins/icon_config";

  export default {
    name: "IconSupportTicketActive",
    mixins: [icon_config],
  }
</script>

<style scoped>

</style>
