<template>
  <AppLoader
    v-if="!userRole"
    style="margin: 10px"
    center-class="offer"
  />
  <AppMainContainer
    v-else
    center-class="offer"
    :show-modal="!!currentModal"
  >
    <template #subheader>
      <PageHeader :isAdmin="isOrganizationRoot && !officeExists" />
      <OfficeSelect
        v-if="!hasSelectedTicket"
        :requestEmpty="true"
      />
    </template>
    <template #center>
      <SupportFilterBarCenter
        v-if="!hasSelectedTicket"
        :filters="filters"
        :smart="hasSelectedTicket"
        @changeStatus="(value) => filters.statusGroup = value"
        @onAddButtonClick="onAdd"
      />
      <div
        class="ticket-list-container"
        :class="{'chat-opened': currentTicket && currentTicket.id }"
        :style="getStyle"
      >
        <SupportTicketItem
          v-for="ticket in getTicketList"
          :key="ticket.id"
          :ticket="ticket"
          :isActive="isActive(ticket)"
          :currentTicket="currentTicket"
          @onTicketClick="onTicketClick"
        />
      </div>
      <!--      <WbdChatComponent-->
      <!--        v-if="currentTicket && currentTicket.id"-->
      <!--        :participants="[-->
      <!--          {-->
      <!--            id: 'user1',-->
      <!--            name: 'Matteo',-->
      <!--          },-->
      <!--          {-->
      <!--            id: 'user2',-->
      <!--            name: 'Support',-->
      <!--          }-->
      <!--        ]"-->
      <!--        :ticket="currentTicket"-->
      <!--        :messageList="getCurrentTicketMessageList"-->
      <!--        @sendMessage="sendMessage"-->
      <!--        @chatClose="onChatClose"-->
      <!--      />-->
    </template>
    <template #modal>
      <CompanyProfileModal
        v-if="currentModal === modalList.ADD_TICKET"
        showTopicSelect
        :topicList="getTopicList"
        @close="currentModal = ''"
        @onSave="onSave"
      />
    </template>
  </AppMainContainer>
</template>
<script>

import { mapActions, mapState } from 'vuex'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import userUtil from '@/utils/user-util'
import routerList from '@/router/labels'
import AppLoader from '@/components/AppLoader'
import SupportFilterBarCenter from '@/components/support/SupportFilterBarCenter'
import CompanyProfileModal from '@/components/companyProfile/CompanyProfileModal'
import SupportTicketItem from '@/components/support/SupportTicketItem'
import OfficeSelect from '@/components/common/OfficeSelect'
import PageHeader from '@/components/subheaders/PageHeader.vue'

export default {
  name: 'PageSupport',
  components: {
    PageHeader,
    AppMainContainer,
    SupportTicketItem,
    CompanyProfileModal,
    SupportFilterBarCenter,
    AppLoader,
    OfficeSelect,
  },
  data () {
    return {
      isScroll: false,
      currentTicket: null,
      loading: false,
      faqPath: null,
      currentModal: '',
      modalList: {
        ADD_TICKET: 'add-ticket',
      },
      userRole: null,
      filters: {
        statusGroup: 'all'
      },
    }
  },
  computed: {
    ...mapState('users', {
      'user': 'info'
    }),
    officeExists () {
      const exist = this.$store.state.office.userOffice && this.$store.state.office.userOffice.id
      if (exist) {
        this.$store.dispatch('support/fetchTicketListList')
      }
      return exist
    },
    hasActiveOffice () {
      return (this.user.office && this.user.office.id && !!this.user.office.isActive)
    },
    hasOffice () {
      return (this.user.office && !!this.user.office.id)
    },
    getChatStyle () {
      return this.$store.state.chat.style
    },
    getStyle () {
      //
      if (this.hasSelectedTicket) {
        return { 'maxHeight': this.getChatStyle.height, 'overflowY': 'scroll', 'marginTop': 0, 'marginRight': '47%' }
      }
      return {}
    },
    hasUser () {
      return this.$store.state.users.info && this.$store.state.users.info.id
    },
    getTopicList () {
      return this.$store.state.support.ticketTopicList
    },
    isRoot () {
      return userUtil.isRoot(this.user)
    },
    user () {
      return this.$store.state.users.info
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.user) || userUtil.isAdmin(this.user)
    },
    hasSelectedTicket () {
      return this.currentTicket && !!this.currentTicket.id
    },
    getTicketList () {
      // if (!this.officeExists && !this.isRoot) {
      //   this.$store.commit('support/ticketList', [])
      //   return []
      // }
      const list = this.$store.state.support.ticketList

      return list.sort((a, b) => {
        if (this.isActive(a) && !this.isActive(b)) {
          return -1
        }
        if (!this.isActive(a) && this.isActive(b)) {
          return 1
        }
        return 0
      })
    },
  },
  watch: {
    '$route.params.id': function (officeId) {
      if (!officeId) {
        this.$store.dispatch('support/fetchTicketListList')
      }
    },
    'user' (val) {
      if (val && val.roleList) {
        if (userUtil.isManager(val)) {
          this.userRole = 'manager'
        } else {
          if (userUtil.isRoot(val)) {
            this.userRole = 'administrator'
          }
        }
      }
    }
  },
  beforeDestroy () {
    this.eventsBus.$emit(this.eventNames.CLOSE_CHAT)
  },
  created () {
    // if (!this.$route.params.id) {
    //   this.$store.dispatch('support/fetchTicketListList')
    // }
    this.faqPath = routerList.FAQ_ROUTE_PATH
    this.fetchTicketTopicListList()
    this.$store.dispatch('support/fetchTicketListList')
    this.eventsBus.$on('chatClose', this.onChatClose)
    this.eventsBus.$on(
      this.eventNames.ON_SUPPORT_TICKET_WORKFLOW_DONE_NOTIFICATION, (data) => {
        if (this.currentTicket && this.currentTicket.id === data.resourceId) {
          this.eventsBus.$emit(this.eventNames.CLOSE_CHAT)
        }
        // setTimeout(() => {
        //   this.$store.dispatch('support/fetchTicketListList')
        // }, 500)
      }
    )
  },
  methods: {
    onChatClose () {
      this.$store.commit('chat/options', {})
      this.$store.commit('chat/ticketMessageList', [])
      this.currentTicket = null
    },
    onTicketClick (ticket) {
      if (this.isActive(ticket)) {
        const { topic } = ticket
        const payload = {
          contactId: ticket.id, groupId: ticket.id, chatRoom: 'topic-chat-item', localChatRoom: 'support'
        }
        if (topic) {
          payload.topicId = topic.id
        }
        console.log(payload, 'payload')
        this.$store.commit('chat/ticketMessageList', [])
        this.$store.dispatch('chat/fetchHistoryMessages',
          payload
        )
        this.$store.commit('chat/options', {
          sendMessage: this.sendMessage,
          onChatClose: this.onChatClose,
          ticket,
        })
        this.$store.commit('chat/show', true)
        this.currentTicket = ticket
      } else {
        this.eventsBus.$emit(this.eventNames.CLOSE_CHAT)
      }
    },
    ...mapActions({
      fetchTicketTopicListList: 'support/fetchTicketTopicListList',
    }),
    isActive (ticket) {
      return ['created', 'active'].includes(ticket.status)
    },
    closeModal () {
      this.currentModal = ''
    },
    onAdd () {
      this.currentTicket = null
      this.currentModal = this.modalList.ADD_TICKET
    },
    onSave (request) {
      const requestData = { ...request }
      if (request.file && request.file.id) {
        requestData.mediaList = [request.file]
      }
      delete requestData.file
      try {
        // this.loading = true
        this.$store.dispatch('support/createSupportTicket', requestData).then((response) => {
          this.$store.dispatch('support/fetchTicketListList')
          this.loading = false
        })
      } catch (e) {
        this.loading = false
      }
    },
    sendMessage (messageData) {
      this.$store.commit('chat/addTicketMessageList', messageData)

      const { topic } = this.currentTicket


      const params = {
        message: messageData.data.text,
      }
      if (topic) {
        params.topic = { id: topic.id }
      }

      console.log(params, 'this.currentTicket')

      // createChatMessage
      this.$store.dispatch(
        'chat/createChatMessage',
        { params }
      ).then((response) => {
        this.$store.dispatch('support/fetchTicketListList')
      })
    }
  }
}
</script>
<style lang="sass">
  .chat-opened
    margin-right: 45%
  .active-ticket
    padding: 8px
    font-style: italic
    border: 1.5px solid rgba(139, 209, 24, 0.7)
    box-sizing: border-box
    box-shadow: 0 5px 15px rgba(139, 209, 24, 0.7)
    border-radius: 6px
  .dot
    display: flex
    justify-content: center
    align-items: center
    width: 12px
    height: 13px
    background: #8BD118
    position: absolute
    border-radius: 50%
    right: -9px
    top: -7px
    font-size: 9px
    text-align: center
    color: white
    //display: inline-block
    //width: 7px
    //height: 7px
    //background: #8BD118 none repeat scroll 0 0
    //position: absolute
    //border-radius: 50%
    //right: -4px
    //top: -2px
  .ticket-list-container
    box-shadow: 0 5px 15px rgba(117, 117, 117, 0.1)
    border-radius: 6px
    margin-top: 20px
    &__item
      display: flex
      cursor: pointer
      flex-direction: row
      align-items: center
      padding: 8px
      font-style: italic
      background: #FFFFFF
      margin: 10px auto
    &__icon
      margin-right: 22px

    &__icon-left
      margin-left: 22px
      align-self: flex-end

    &__description
      margin-bottom: 5px
      font-size: 14px
      line-height: 140%

    &__link
      font-weight: 800
      text-decoration: none

    &__date
      font-size: 12px
      line-height: 15px
      opacity: 0.3
      &__inner
        display: inline-block
        padding-right: 10px
        margin-right: 10px
        border-right: 1px solid #757575
    &__text
      font-size: 14px
      line-height: 16px
      border: 1px solid #979696
      padding: 6px
      margin: 6px
      border-radius: 6px
      opacity: 0.6
</style>

