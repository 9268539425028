<template>
  <AppModalMiddle class="p-5">
    <ModalHeader
      class="mb-3"
      @close="onClose"
    >
      Создание запроса в поддержку
    </ModalHeader>
    <div class="d-flex flex-column">
      <div
        v-if="showTopicSelect"
        class="mb-3"
      >
        <AppSelect
          v-model="request.topic"
          :visualValue="request.topic.title"
          placeholder="Тема запроса"
          :values="topicList"
        >
          <template #default="{ value }">
            {{ value.title }}
          </template>
        </AppSelect>
      </div>
      <h3
        v-else
        class="mb-3"
      >
        Изменение реквизитов
      </h3>
      <textarea
        v-model="request.description"
        :max="5000"
        class="app-textarea mb-2"
        placeholder="Сообщите о реквизитах, которые необходимо изменить"
      />
      <div
        class="d-flex align-items-center mb-2 cursor-pointer"
        @click="startRequestFileUpload"
      >
        <input
          ref="request"
          class="d-none"
          type="file"
          @change="requestFile = $refs.request.files[0]"
        >
        <IconAttachFile class="mr-2" />
        <small class="text-gray">{{ requestFile ? requestFile.name : 'Прикрепить файл' }}</small>
      </div>
      <AppButton
        class="mx-auto"
        :disabled="loader || (!request.description.length && !requestFile)"
        @click.native="sendRequest"
      >
        Создать запрос
      </AppButton>
      <AppButton
        style="margin-top:  24px"
        class="button-text-simple"
        @click.native="onClose"
      >
        Отмена
      </AppButton>
    </div>
  </AppModalMiddle>
</template>

<script>
import AppModalMiddle from '@/components/common/modal/AppModalMiddle'
import AppButton from '@/components/common/simple/AppButton'
import ModalHeader from '@/components/common/modal/ModalHeader'
import IconAttachFile from '@/components/common/icons/IconAttachFile'
import AppSelect from '@/components/common/simple/AppSelect'

import { mapActions } from 'vuex'

export default {
  name: 'CompanyProfileModal',
  components: {
    AppSelect,
    AppModalMiddle,
    AppButton,
    ModalHeader,
    IconAttachFile,
  },
  props: {
    showTopicSelect: {
      type: Boolean,
      default: false
    },
    onSave: {
      type: Boolean,
      default: false
    },
    topicList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    loader: false,
    requestFile: null,
    request: {
      topic: {
        title: null,
        id: null,
      },
      description: '',
      file: {
        id: null,
      },
    }
  }),
  beforeDestroy () {
    this.onClose()
  },
  methods: {
    ...mapActions({
      sendTicket: 'companyProfile/sendTicket'
    }),
    startRequestFileUpload () {
      this.$refs.request.click()
    },
    async sendRequestFile () {
      const { data } = await this.$store.dispatch('uploadClientFile', this.requestFile)
      this.request.file.id = data.id
      return Promise.resolve()
    },
    async sendRequest () {
      this.loader = true
      try {
        if (this.requestFile) {
          await this.sendRequestFile()
        }
        if (this.showTopicSelect) {
          this.$emit('onSave', this.request)
        } else {
          await this.sendTicket(this.request)
        }
        this.onClose()
      } finally {
        this.loader = false
      }
    },
    onClose () {
      this.request = {
        topic: {
          title: null,
          id: null,
        },
        description: '',
        file: {
          id: null,
        },
      }
      this.$emit('close')
    }
  }
}
</script>
