<template>
  <svg :width="width" :height="height" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10.25" stroke="#8BD118" stroke-width="1.5"/>
    <path d="M17.5 7.23899L16.2743 6L8.83331 13.5219L5.72567 10.3805L4.5 11.6195L8.83323 16L17.5 7.23899Z" fill="#8BD118"/>
  </svg>
</template>

<script>
  import icon_config from "@/mixins/icon_config";

  export default {
    name: "IconSupportTicketActive",
    mixins: [icon_config],
  }
</script>

<style scoped>

</style>
